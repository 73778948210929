<template>
  <DataTable
      v-model:value="rows"
      v-model:filters="filters"
      :globalFilterFields="globalFilterFields"
      class="p-datatable-small"
      scrollable scrollHeight="600px"
      tableStyle="min-width: 50rem"
      @filter="onFilter"
  >
    <template #header>
      <div class="flex gap-2">
            <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value"
                           class="w-full"
                           placeholder="Búsqueda por palabra clave"
                           size="small"/>
            </span>
            <Button label="Agregar" size="small" @click="onMOdalAgregar"/>
      </div>
    </template>
    <Column header="Acciones" class="">
      <template #body="{ data }">
        <div class="flex flex-col gap-2 my-auto">
          <Checkbox v-model="data.Status" :binary="true"  />
          <span class="pi pi-pencil text-blue-600" @click="onEditLine(data)"/>
<!--          <span class="pi pi-copy text-green-600 cursor-pointer" @click="onDuplicate(data)" aria-haspopup="true" aria-controls="overlay_menu" />-->
        </div>
      </template>
    </Column>
    <Column header="Articulo" >
      <template #body="{data, index}">
        <div class=" flex flex-col">
          <span class="text-xs">
            Cod: {{ data.ItemCode }}
          </span>
          <span class="text-xs">
            Descripción: {{ data.ItemName }}
          </span>
          <div class="text-xs flex">
            <span class="my-auto">Codigo :</span>
            <svg :id="index+'-'+data.CodeBars"  height="15px"/>
          </div>
        </div>
      </template>
    </Column>
    <Column header="Lote" >
      <template #body="{ data }">
        <div class=" flex flex-col">
          <span class="text-xs">
            Lote: {{ data.NumLote }}
          </span>
          <span class="text-xs">
            Fecha Ven: {{ data.FecVen }}
          </span>
        </div>
      </template>
    </Column>
    <Column header="Cantidad" >
      <template #body="{ data }">
        <span class="text-xs">
          {{ data.Quantity }}
        </span>
      </template>
    </Column>
  </DataTable>
  <Modificar :visible="visible" :data="data" ref="modificarDialog" />
</template>
<script setup>
import { defineAsyncComponent, ref, computed } from 'vue'
import { useVer } from '../../../store/ver.store'
import { FilterMatchMode } from 'primevue/api'
import JsBarcode from 'jsbarcode'
const Modificar = defineAsyncComponent(() => import('./modificarDialog.vue'))
const storeVer = useVer()
const rows = computed(() => {
  paintCodeBars(storeVer._envio)
  return storeVer._envio
})
const modificarDialog = ref(null)
const onEditLine = (data) => {
  modificarDialog.value.onVisible({ valor: true, data, add: false })
}
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const globalFilterFields = ['ItemCode', 'ItemName', 'NumLote', 'CodeBars']
const paintCodeBars = (data) => {
  setTimeout(() => {
    data.forEach((row, index) => {
      const svg = document.getElementById(index + '-' + row.CodeBars)
      JsBarcode(svg, row.CodeBars, {
        format: 'CODE128',
        lineColor: '#495057',
        displayValue: true,
        fontSize: 10,
        textMargin: 0,
        width: 1,
        height: 15
      })
    })
  }, 0)
}
const onFilter = (e) => {
  paintCodeBars(e.filteredValue)
}
// const onDuplicate = (data) => {
//   storeVer.duplicateRow(data)
// }
const onMOdalAgregar = () => {
  modificarDialog.value.onVisible({ valor: true, data: null, add: true })
}

</script>
<style scoped>

</style>
